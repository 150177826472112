import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from 'react-bootstrap/Container';
import rblogo from "../pictures/IA-logo-inverse_02.png"
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { infoData } from "../App";
import packageJson from "../../package.json"

/**
 * Renders the navbar component with a sign in or sign out button depending on whether or not a user is authenticated
 * @param props
 */

export const PageLayout = (props) => {
const { signOut } = useAuthenticator()
  return (
    <>
      <Navbar bg="IA" style={{height:"80px"}}>
        <Container>
          <Navbar.Brand href="/">
            <img
              src={rblogo}
              width="300"
              height="120"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
          <Nav>
            <NavDropdown
              id="nav-dropdown-dark-example"
              title={'Přihlášený uživatel ' + infoData.idToken.payload.name + ` ` + infoData.idToken.payload.family_name}
              menuVariant="dark"
            >
              <NavDropdown.Item href="/user">Informace o uživateli</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="https://InnovationAnywhere.cz" target="_blank" rel="noopener noreferrer">Innovation Anywhere</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as="button" onClick={() => signOut()}>
                Odhlášení
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <br />
      <br />
      <h5>
        <center>
        </center>
      </h5>
      <br />
      <br />
      {props.children}
      <br />
      <br />
      <h5>
        <center>
        </center>
      </h5>
      <br />
      <br />
        <div className='text-center p-2 fixed-bottom' style={{ backgroundColor: "#91d0da" }}>
          <a className='text-reset fw-bold' href="https://InnovationAnywhere.cz" target="_blank" rel="noopener noreferrer">
          2023 © Innovation Anywhere s.r.o.
          </a>
          <br></br>
          <a>v{packageJson.version}</a>
        </div>

    </>
  );
};