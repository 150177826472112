import { useState } from "react";
import NumberInput from "./NumberInput";
import Select from "react-select";
import Result from "./Result";
import { Button } from "react-bootstrap";

import { calculate, options, optionsComplication } from "../calculator/helpers";
import { postCalculation } from "../../functions/API";

const CalculatorForm = (props) => {
	const { a, b, c, d, e, f, g } = props;

	const [hiddenA, setHiddenA] = useState(true);
	const [hiddenB, setHiddenB] = useState(true);
	const [hiddenD, setHiddenD] = useState(true);
	const [hiddenG, setHiddenG] = useState(true);

	const [selectedOptionStateA, setSelectedOptionStateA] = useState({
		value: "Je součástí i SAP? (Ano/Ne)",
		label: "Je součástí i SAP? (Ano/Ne) ",
	});

	const [selectedOptionStateB, setSelectedOptionStateB] = useState({
		value: "Jsou součástí procesu jiné aplikace?",
		label: "Jsou součástí procesu jiné aplikace?",
	});
	const [selectedOptionStateC, setSelectedOptionStateC] = useState({
		value: "Bude využito v procesu zasílání emailů?",
		label: "Bude využito v procesu zasílání emailů?",
	});

	const [selectedOptionStateD, setSelectedOptionStateD] = useState({
		value: "Je součástí procesu vyčítání dokumentu pomocí Regexů?",
		label: "Je součástí procesu vyčítání dokumentu pomocí Regexů?",
	});
	const [selectedOptionStateE, setSelectedOptionStateE] = useState({
		value: "Jsou očekávány komplikace při vývoji?",
		label: "Jsou očekávány komplikace při vývoji?",
	});

	const [selectedOptionStateF, setSelectedOptionStateF] = useState({
		value: "Přejete si zahrnout administrativu do pracnosti?",
		label: "Přejete si zahrnout administrativu do pracnosti?",
	});

	const [selectedOptionStateG, setSelectedOptionStateG] = useState({
		value: "Bude využito API pro komunikaci s aplikacemi?",
		label: "Bude využito API pro komunikaci s aplikacemi?",
	});

	const [resultState, setResultState] = useState(null);

	const handleSubmit = (event) => {
		event.preventDefault();
		const result = calculate(a, b, c, d, e, f, g, 
		selectedOptionStateA.value, selectedOptionStateB.value, selectedOptionStateC.value, 
		selectedOptionStateD.value, selectedOptionStateE.value, selectedOptionStateF.value,
		selectedOptionStateG.value);
		postCalculation()
		setResultState(result);
	};

	const handleChangeA = (selectedOptionStateA) => {
		setSelectedOptionStateA(selectedOptionStateA);
		setHiddenA(selectedOptionStateA.value);
	};

	const handleChangeB = (selectedOptionStateB) => {
		setSelectedOptionStateB(selectedOptionStateB);
		setHiddenB(selectedOptionStateB.value);
	};
	const handleChangeC = (selectedOptionStateC) => {
		setSelectedOptionStateC(selectedOptionStateC)
	};

	const handleChangeD = (selectedOptionStateD) => {
		setSelectedOptionStateD(selectedOptionStateD);
		setHiddenD(selectedOptionStateD.value);
	};
	const handleChangeE = (selectedOptionStateE) => {
		setSelectedOptionStateE(selectedOptionStateE)
	};

	const handleChangeF = (selectedOptionStateF) => {
		setSelectedOptionStateF(selectedOptionStateF);
	};

	const handleChangeG = (selectedOptionStateG) => {
		setSelectedOptionStateG(selectedOptionStateG);
		setHiddenG(selectedOptionStateG.value)
	};


	return (
		<div>
			<form className="CalculatorForm" onSubmit={handleSubmit}>
				<Select
					onChange={handleChangeA}
					value={selectedOptionStateA}
					options={options}
				/>
				{!hiddenA ? 	<NumberInput
					onChange={props.aOnChange}
					name="a"
					label="uveďte počet transakcí"
					value={props.a}
				/> : null}
				<Select
					onChange={handleChangeB}
					value={selectedOptionStateB}
					options={options}
				/>
				{!hiddenB ? <NumberInput
					onChange={props.bOnChange}
					name="b"
					label="Počet obrazovek v jiných aplikacích:"
					value={props.b}
				/> : null}
				{!hiddenB ? <NumberInput
					onChange={props.gOnChange}
					name="g"
					label="Počet dalších aplikací"
					value={props.g}
				/> : null}
				{!hiddenB ? <Select
					onChange={handleChangeG}
					value={selectedOptionStateG}
					options={options}
				/> : null}
				{!hiddenG ? <NumberInput
					onChange={props.fOnChange}
					name="f"
					label="Očekávaný počet vystavených APIs:"
					value={props.f}
				/> : null}
				<Select
					onChange={handleChangeC}
					value={selectedOptionStateC}
					options={options}
				/>
				<NumberInput
					onChange={props.cOnChange}
					name="c"
					label="Počet rozhodovacích úkonů"
					value={props.c}
				/>
				<Select
					onChange={handleChangeD}
					value={selectedOptionStateD}
					options={options}
				/>
				{!hiddenD ? <NumberInput
					onChange={props.dOnChange}
					name="d"
					label="Počet rozdílných dokumentů"
					value={props.d}
				/> : null}
				{!hiddenD ? <NumberInput
					onChange={props.eOnChange}
					name="e"
					label="Počet položek v dokumentu"
					value={props.e}
				/> : null}
				<Select
					onChange={handleChangeE}
					value={selectedOptionStateE}
					options={optionsComplication}
				/>
				<Select
					onChange={handleChangeF}
					value={selectedOptionStateF}
					options={options}
				/>
				<br />
				<Button variant="IA-Button" type="submit" style={{marginRight: '10px'}}>Calculate</Button>
				<Button href='/' variant="IA-Button" type='submit'>Zpět</Button>
			</form>
			<Result value={resultState} />
		</div>
	);
};

export default CalculatorForm;
