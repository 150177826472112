import { Amplify, Auth } from 'aws-amplify';
import {COGNITO_DOMAIN, COGNITO_REGION, COGNITO_SIGNIN_REDIRECT, COGNITO_SIGNOUT_REDIRECT, COGNITO_USERPOOLID, COGNITO_USERPOOLWEBCLIENTID} from './store/global-variables'

// IN CASE OF CHANGING AWS COGNITO SOURCE CHANGE THIS ATTRIBUTES:
// REGION
// USERPOOLID
// USERPOOLWEBCLIENTID
// OAUTH INFO: DOMAIN, SCOPE, REDIRECT SIGN IN, SIGNOUT, RESPONSETYPE IF NEEDED


Amplify.configure({
  Auth: {

    // REQUIRED - Amazon Cognito Region
    region: COGNITO_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: COGNITO_USERPOOLID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: COGNITO_USERPOOLWEBCLIENTID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
    // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
    signUpVerificationMethod: 'code', // 'code' | 'link'

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol


    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: COGNITO_DOMAIN,
      scope: [
        'phone',
        'email',
        'openid',
        'profile',
        'rsts-ms-signpost/all'
      ],
      redirectSignIn: COGNITO_SIGNIN_REDIRECT,//'http://localhost:3000, // change on current S3 or localhost adress
      redirectSignOut: COGNITO_SIGNOUT_REDIRECT,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});


// You can get the current config object
const currentConfig = Auth.configure();

export default currentConfig