import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import React from "react";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';


export function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Zadání nového Timesheetu
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridUserName">
                        <Form.Label>Jméno</Form.Label>
                        <Form.Control
                        type="text"
                        />
                    </Form.Group>

                    <Form.Group as={Col} controlId="Phone">
                        <Form.Label>Příjmení</Form.Label>
                        <Form.Control
                        type="text"
                        />
                    </Form.Group>
             </Row>
            <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridOd">
                <Form.Label>Datum od:
                </Form.Label>
                <Form.Control
                    type="date"
                />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridOd">
                <Form.Label>Datum do:
                </Form.Label>
                <Form.Control
                    type="date"
                />
                </Form.Group>
            </Row>

            <Form.Group className='mb-3' controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="text"
                    placeholder='test'
                    aria-label="Disabled input example"
                    disabled
                    readOnly
                />
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button href="/" variant="IA-button" type="submit">Potvdit</Button>
            <Button onClick={props.onHide} variant="IA-button" type="submit">Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  