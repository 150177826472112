import { env } from '../env'

// COGNITO Variables
export const COGNITO_REGION=env.REACT_APP_REGION;
export const COGNITO_USERPOOLID=env.REACT_APP_USERPOOLID;
export const COGNITO_USERPOOLWEBCLIENTID=env.REACT_APP_USERPOOLWEBCLIENTID;
export const COGNITO_DOMAIN=env.REACT_APP_DOMAIN;
export const COGNITO_SIGNIN_REDIRECT=env.REACT_APP_SIGNIN_REDIRECT;
export const COGNITO_SIGNOUT_REDIRECT=env.REACT_APP_SIGNOUT_REDIRECT;

// API Variables
export const REACT_APP_API_URL=env.REACT_APP_API_URL_1;

// APLICATION CONFIG
export const APP_CONFIG=env.REACT_APP_CONFIG

/*
export const COGNITO_REGION='eu-central-1';
export const COGNITO_USERPOOLID='eu-central-1_EJ4ZuC2dl';
export const COGNITO_USERPOOLWEBCLIENTID='4in158abtftbknttv4l0inlh60';
export const COGNITO_DOMAIN='iaportal.auth.eu-central-1.amazoncognito.com';
export const COGNITO_SIGNIN_REDIRECT='http://localhost:3000';
export const COGNITO_SIGNOUT_REDIRECT='https://iaportal.auth.eu-central-1.amazoncognito.com/login?client_id=4in158abtftbknttv4l0inlh60&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https%3A%2F%2Fd1xifnoqjncuqd.cloudfront.net'

// API Variables
export const REACT_APP_API_URL='https://d3dhps2vazqyli.cloudfront.net';

export const APP_CONFIG='https://d1xifnoqjncuqd.cloudfront.net/data.json'
*/