import { Auth } from 'aws-amplify';
import { COGNITO_SIGNOUT_REDIRECT, APP_CONFIG } from '../store/global-variables';
import { infoData } from '../App';




//GET CURENT SESSION FROM COGNITO = ID TOKEN, ACCESS TOKEN
export async function fetchData() {
    try {
      const response = await Auth.currentSession()
      return response
    } catch(error) {
      window.location.href = COGNITO_SIGNOUT_REDIRECT;
    }
  }
  
// GET APPLICATION CONFIG JSON
export async function getAppJson() {
  const url = APP_CONFIG
  try {
    let response = await fetch(url);
    let responseJson = await response.json();
    return responseJson;
   } catch(error) {;
  }
}

// CREATE OBJECT FOR MAIN VIEW

export async function createDataSet(setOfApplications) {
  const appConfigFull = await getAppJson()
  const appConfig = appConfigFull.applications
  const finalSetofApplications = [];
  const applicationConstructor = 	{
		"name":"",
		"url":"/",
    "extApp":false,
		"pic": "https://pripojse.rsts-dev.cz/applicationPictures/rsts-logo.jpg",
		"description":"Some text",
    "style": {width:'100', paddingTop: '10%', paddingBottom:'10%'}
	};
  setOfApplications.forEach(element => {
    appConfig.map((app) => {
      if(app.name === element) {
        const application = Object.create(applicationConstructor)
        application.name = element;
        application.url = selectSDEndpoint(app.url, appConfigFull.ServiceDeskUrl)
        application.extApp = app.extApp
        application.pic = app.pic
        application.description = app.description
        application.style =  app.style
        finalSetofApplications.push(application)
      }
  })
  })
  return finalSetofApplications
}

// SELECTION OF RIGHT SERVICE DESK ENDPOINT

export function selectSDEndpoint(appUrl, appConfigURL) {
  if(appUrl === "") {
    appUrl = appConfigURL.map((client) => {
      if(client.name === infoData.idToken.payload['custom:Company'])
        return client.clientURL
    })
    return appUrl
  } else return appUrl  
}

export async function createCarouselSet(setOfApplications) {
  const carConfigFull = await getAppJson()
  const carConfig = carConfigFull.carousel
  const finalSetofSlides = [];
  const carouselConstructor = 	{
		"name":"",
		"url":"/",
    "desc":"",
	};
    carConfig.map((slide) => {
        const slides = Object.create(carouselConstructor)
        slides.name = slide.name;
        slides.url = slide.clientURL
        slides.desc = slide.desc
        finalSetofSlides.push(slides)
  })
  return finalSetofSlides
}