import './App.css';
import { PageLayout } from './components/PageLayout';
import Main from "./components/MainView";
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import currentConfig from './aws-exports';
import UserView from './components/UserView';
import { Routes, Route } from "react-router-dom";
import { fetchData } from './functions/functions';
import { intiApplications } from './functions/API';
import { createDataSet, createCarouselSet } from './functions/functions';
import axios from 'axios';
import { REACT_APP_API_URL } from './store/global-variables';
import Calculator from './components/calculator';
import { TimesheetView } from './components/timesheetView';
import ContactView from './components/contactView';
import DarkVariantExample from './components/catalog'
import {env} from './env'

// CONFIGURATION OF AMPLIFY
Amplify.configure(currentConfig)


// GET DATA FROM CURRENT SESSION
export const infoData = await fetchData()


// AXIOS MAIN SETUP
export const instance = axios.create({
  baseURL: REACT_APP_API_URL,
  timeout: 1000,
  headers: {'Authorization': `Bearer ${infoData.accessToken.jwtToken}`}
});

// GET APPLICATION VIA API
export const setOfApplications = await intiApplications()

export const finalSetOfApplications = await createDataSet(setOfApplications)

export const finalSetOfCarousels = await createCarouselSet(setOfApplications)

// APPLICATIONS VIEW FILLER
await createDataSet(setOfApplications)

const MainContent = () => {
  return (
      <div className="App">
            <Routes>
                <Route path='/' element={<Main />}/>
                <Route path='/user' element={<UserView/>}/>
                <Route path='/calculator' element={<Calculator/>}/>
                <Route path='/timesheets' element={<TimesheetView/>}/>
                <Route path='/contact' element={<ContactView/>}/>
                <Route path='/catalog' element={<DarkVariantExample/>}/>
            </Routes>
      </div>
  );
};

function App() {
  return (
      <PageLayout>
          <center>
            <MainContent />
          </center>
      </PageLayout>
  );
}

export default withAuthenticator(App)