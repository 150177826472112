import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './App';
import "./custom.scss"
import { BrowserRouter } from 'react-router-dom';
// Bootstrap components
//import 'bootstrap/dist/css/bootstrap.min.css';


const root = ReactDOM.createRoot(document.getElementById('root'));

 root.render(
  <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
  </React.StrictMode>
)