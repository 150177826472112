import React, { useState } from "react";
import CalculatorForm from "../handlers/calculator/CalculatorForm";
import "./calculator.css";

const title = "Výpočet pracnosti";

const Calculator = () => {
	const [aState, setAState] = useState(0);
	const [bState, setBState] = useState(0);
	const [cState, setCState] = useState(0);
	const [dState, setDState] = useState(0);
	const [eState, setEState] = useState(0);
	const [fState, setFState] = useState(0);
	const [gState, setGState] = useState(0);

	const inputAonchange = (event) => {
		console.log(event.target.value);
		setAState(event.target.value);
	};

	const inputBonchange = (event) => {
		console.log(event.target.value);
		setBState(event.target.value);
	};

	const inputConchange = (event) => {
		console.log(event.target.value);
		setCState(event.target.value);
	};
	const inputDonchange = (event) => {
		console.log(event.target.value);
		setDState(event.target.value);
	};

	const inputEonchange = (event) => {
		console.log(event.target.value);
		setEState(event.target.value);
	};
	const inputFonchange = (event) => {
		console.log(event.target.value);
		setFState(event.target.value);
	};

	const inputGonchange = (event) => {
		console.log(event.target.value);
		setGState(event.target.value);
	};
	
	return (
		<div className="App">
			<h1>{title}</h1>
			<CalculatorForm
				aOnChange={inputAonchange}
				bOnChange={inputBonchange}
				cOnChange={inputConchange}
				dOnChange={inputDonchange}
				eOnChange={inputEonchange}	
				fOnChange={inputFonchange}	
				gOnChange={inputGonchange}						
				a={aState}
				b={bState}
				c={cState}
				d={dState}
				e={eState}
				f={fState}
				g={gState}
			/>
		</div>
	);
};

export default Calculator;
