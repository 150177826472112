import { instance } from '../App';

// GET DATA FROM BACK END

export async function intiApplications() {
	let data = 
	await instance.get('/applications')
	  .then(response => {
		return response.data.applications
	}).catch(error => {console.log(error)})
	return data
  };

  export async function postCalculation() {
	await instance.post('/calculator', 
		{name:"test",
		descption:"test",
		price:3.2,
		tax:3.0}
		).then(response => {
		console.log(response)
		return response
	}).catch(error => {console.log(error)})
}






