import Carousel from 'react-bootstrap/Carousel';
import { Container } from 'react-bootstrap';
import { finalSetOfCarousels } from '../App';

function DarkVariantExample() {
const slides = finalSetOfCarousels
console.log(slides)
  return (
    <Container>
        <Carousel data-bs-theme="light">
            {slides.map((slide) => (
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={slide.url}
                    alt="First slide"
                    />
                    <Carousel.Caption>
                    <h5>{slide.name}</h5>
                    <p>{slide.desc}</p>
                    </Carousel.Caption>
        </Carousel.Item>
        ))}
        </Carousel>
    </Container>
  );
}

export default DarkVariantExample;