import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { Container } from "react-bootstrap";
import { finalSetOfApplications } from '../App';

// CARDS OF APPLICATIONS
function Main() {
const applicationData = finalSetOfApplications
	return (
	<Container>
	  <Row xs={1} md={5} className="g-4" style={{justifyContent:"center"}}>
			{applicationData.map((application) => (
				<Col>
				  <Card style={{height:"100%"}}>
					<Card.Img variant='top' src={application.pic} style={application.style}/>
					<Card.Body>
					  <Card.Title>{application.name}</Card.Title>
					  <Card.Text>
						  {application.description}
					  </Card.Text>
					</Card.Body>
                    {application.extApp ? <Button href={application.url} variant="IA-Button" target="_blank" rel="noopener noreferrer">Otevřít aplikaci</Button> : <Button href={application.url} variant="IA-Button">Otevřít aplikaci</Button>}
				  </Card>
				</Col>
		))}
	  </Row>
	</Container>
	);
  }
  
  export default Main;