import React from "react";
import { Button } from "react-bootstrap";
import { MyVerticallyCenteredModal } from "../handlers/modals/timesheetModal";
import Table from "react-bootstrap/Table";

  
export const TimesheetView = () => {
    const [modalShow, setModalShow] = React.useState(false);
  
    return (
      <>
        <Button variant="IA-button" onClick={() => setModalShow(true)}>
            Vytvořit Timesheet
        </Button>
        <br />
        <Table striped bordered hover>
        <thead>
            <tr>
            <th>Datum od do</th>
            <th>jméno</th>
            <th>Klient</th>
            <th>Odpracovaný čas</th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td>26.06.2023 - 02.07.2023</td>
            <td>Václav Hruška</td>
            <td>škoda</td>
            <td>8 Hodin</td>
            </tr>
        </tbody>
        </Table>
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </>
    );
  }