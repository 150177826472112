export const options = [
    { value: false, label: "Yes" },
    { value: true, label: "No" },
];

export const optionsComplication = [
    { value: 1, label: "Žádné" },
    { value: 1.2, label: "Lehké" },
    { value: 1.3, label: "Střední" },
    { value: 1.5, label: "Velké" },
];


export let calculate = (a, b, c, d, e, f, g, operation, operationB, operationC, operationD, operationE, operationF, operationG) => {
    let numberA = !operation ? parseFloat(a) : 0; // počet sapových transakcí
    let numberB = !operationB ? parseFloat(b) : 0; // počet jiných aplikací automatizovaných pomocí UI
    let numberJ = !operationG ? parseFloat(g) : 0 // počet stránek na aplikaci
    let numberC = !operationC ? 1 : 0; // použití emailu
    let numberD = parseFloat(c) // počet rozhodovacích úkonů
    let numberE = !operationD ? parseFloat(d) : 0; // Počet rozdílných dokumentů pro Regex
    let numberF = !operationD ? parseFloat(e) : 0; // Počet položek v dokumentů po regex
    let numberG = parseFloat(operationE); // očekávané obtíže při vývoji
    let numberH = !operationF ? 0.5 : 0; // položka administrativa
    let numberI = !operationG ? parseFloat(f) : 0 // počet jiných aplikací automatizovaných pomocí API


    let subtotal = ((numberA * 3 + numberB * numberJ + numberI * 0.1 + numberC + 
    numberD * 0.2 + numberE * numberF * 0.01) * numberG);

    let admin = subtotal/5 * numberH;
    let analysis = subtotal/5 * 0.5;
    let testing = subtotal/5 * 1;
    let qa = subtotal/10 * 1;
    let production = 0.2; 

    return (subtotal + admin + analysis + testing + qa + production).toFixed(1);


};