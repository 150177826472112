import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { infoData } from "../App";
import { Container } from 'react-bootstrap';

// VIEW OF USER INFORMATIONS

function ContactView() {
  return (
    <Container>
      <Form>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridName">
          <Form.Label>Jméno</Form.Label>
          <Form.Control
            type="text"
            placeholder='Martin'
            aria-label="Disabled input example"
            disabled
            readOnly
          />
        </Form.Group>
        <Form.Group as={Col} controlId="formGridName2">
          <Form.Label>Přijmení</Form.Label>
          <Form.Control
            type="text"
            placeholder='Janečka'
            aria-label="Disabled input example"
            disabled
            readOnly
          />
        </Form.Group>
        </Row>
        <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder='Martin.Janecka@innovationanywhere.cz'
              aria-label="Disabled input example"
              disabled
              readOnly
            />
          </Form.Group>

          <Form.Group as={Col} controlId="Phone">
            <Form.Label>Telefonní číslo</Form.Label>
            <Form.Control
              type="text"
              placeholder={infoData.idToken.payload.phone_number}
              aria-label="Disabled input example"
              disabled
              readOnly
            />
          </Form.Group>
        </Row>
      <Button href="/" variant="IA-button" type="submit">
        Zpět
      </Button>
    </Form>
    </Container>
  );
}

export default ContactView;